import { defineStore } from 'pinia'
import type { components } from '~/interfaces/api/api'

type CourseCategory = components['schemas']['CourseCategory']

export const useCourseCategoryStore = defineStore('courseCategory', {
  state: () => {
    return {
      tree: {
        list: [] as Array<CourseCategory>,
        isLoading: false,
        isError: false,
      },
      list: [] as Array<CourseCategory>,
      isLoading: false,
      isError: false,
    }
  },
  actions: {
    async loadCourseCategories(forceLoading = false) {
      if (forceLoading || (this.tree.list.length === 0 && this.tree.isLoading === false)) {
        this.tree.isLoading = true
        this.tree.isError = false
        try {
          const data = await useNuxtApp().$api.course.getCoursesCategories()
          this.setCourseCategories(data)
          this.tree.isLoading = false
        }
        catch (error: any) {
          this.tree.isLoading = false
          this.tree.isError = true
          throw new Error(error)
        }
      }

      return true
    },
    async loadCourseCategory(payload: { slug: string, scope: 'courses' | 'subcategories' | undefined }) {
      if (this.isLoading === false && this.getCourseCategoryBySlug(payload.slug) === undefined) {
        payload.slug = payload.slug || 'all'
        this.isLoading = true
        this.isError = false
        try {
          const data = await useNuxtApp().$api.course.getCoursesCategoriesSlug(payload.slug, { page: 1, per_page: 24, scope: payload.scope })
          this.setCourseCategories(data.data)
          this.tree.isLoading = false
        }
        catch (error: any) {
          this.tree.isLoading = false
          this.tree.isError = true
          throw new Error(error)
        }
      }
      return true
    },
    setCourseCategories(categories: any) {
      this.tree.list = []
      categories.forEach((category: any) => {
        // iterate over all slugs and split them, because backend slug is "category/subcategory"
        if (category.subcategories) {
          category.subcategories?.forEach((subcategory: any) => {
            const slugs = subcategory.slug.split('/')
            subcategory.parent_slug = slugs[0]
            subcategory.slug = slugs[1]
          })
        }
        this.tree.list.push(category)
      })
    },

  },
  getters: {
    getCourseCategories: state => state.tree.list,
    getCourseCategoriesForFilter: (state) => {
      const categories = state.tree.list.filter(category => category.visibility.filter === 'true')
      return categories.map((category: any) => {
        return { ...category, subcategories: category.subcategories?.filter((subcategory: any) => subcategory.visibility.filter === 'true') }
      })
    },
    getCourseCategoriesForFooter: (state) => {
      const categories = state.tree.list.filter(category => category.visibility.footer === 'true')
      return categories.map((category: any) => {
        return { ...category, subcategories: category.subcategories?.filter((subcategory: any) => subcategory.visibility.footer === 'true') }
      })
    },
    getCourseCategoriesForFeatured: (state) => {
      const categories = state.tree.list.filter(category => category.visibility.featured === 'true')
      return categories.map((category: any) => {
        return { ...category, subcategories: category.subcategories?.filter((subcategory: any) => subcategory.visibility.featured === 'true') }
      })
    },
    getCourseCategoriesForOnboarding: (state) => {
      const categories = state.tree.list.filter(category => category.visibility.onboarding === 'true')
      return categories.map((category: any) => {
        return { ...category, subcategories: category.subcategories?.filter((subcategory: any) => subcategory.visibility.onboarding === 'true') }
      })
    },
    getCourseCategoryBySlug: state => (slug: string) => {
      slug = slug || 'all'
      return state.list.find(category => category.slug === slug)
    },
  },
})
